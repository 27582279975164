import { createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import session from './session';
import productReducer from './products';
import cartItemReducer from './cart_items';
import searchReducer from './search';
import reviewReducer from './reviews';

const rootReducer = combineReducers({
    session,
    products: productReducer,
    cartItems: cartItemReducer,
    searchResults: searchReducer,
    reviews: reviewReducer
})

let enhancer;

if (process.env.NODE_ENV === 'production') {
    enhancer = applyMiddleware(thunk);
  } else {
    const logger = require('redux-logger').default;
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}

const configureStore = (preloadedState) => {
    return createStore(rootReducer, preloadedState, enhancer);
}

export default configureStore;